import { lazy } from 'react'

import { RouteProps } from 'app/shared/types'
import ForgotPasssord from './forgot-password/ForgotPassord'

const AccountPassword = lazy(() => import('./account/password'))
const AccountProfile = lazy(() => import('./account/profile'))
const British = lazy(() => import('./british/British'))
const Home = lazy(() => import('./home'))
const SignIn = lazy(() => import('./membership/signin'))
const SignOut = lazy(() => import('./membership/signout'))
const VerifyAccount = lazy(() => import('./membership/verify-account'))

const AgendaReport = {
  Mobile: lazy(() => import('./agenda-report/mobile')),
  Stres: lazy(() => import('./agenda-report/stres')),
  Second: lazy(() => import('./agenda-report/secondreport')),
}

const BotClassification = {
  Create: lazy(() => import('./bot-classification/CreateBotClassification')),
  List: lazy(() => import('./bot-classification/BotClassificationList')),
  Update: lazy(() => import('./bot-classification/UpdateBotClassification')),
}

const BotControlSet = {
  Create: lazy(() => import('./bot-control-set/CreateBotControlSet')),
  List: lazy(() => import('./bot-control-set/BotControlSetList')),
  Update: lazy(() => import('./bot-control-set/UpdateBotControlSet')),
}

const BlackList = {
  Create: lazy(() => import('./black-list/CreateBlackList')),
  List: lazy(() => import('./black-list/BlackList')),
  Update: lazy(() => import('./black-list/UpdateBlackList')),
}

const ClassificationCategory = {
  Create: lazy(
    () => import('./classification-category/CreateClassificationCategory')
  ),
  List: lazy(
    () => import('./classification-category/ClassificationCategoryList')
  ),
  Update: lazy(
    () => import('./classification-category/UpdateClassificationCategory')
  ),
}

const ClassificationControlSet = {
  Create: lazy(
    () => import('./classification-control-set/CreateClassificationControlSet')
  ),
  List: lazy(
    () => import('./classification-control-set/ClassificationControlSetList')
  ),
  Update: lazy(
    () => import('./classification-control-set/UpdateClassificationControlSet')
  ),
}

const ClassificationDataSet = {
  Create: lazy(
    () => import('./classification-data-set/CreateClassificationDataSet')
  ),
  List: lazy(
    () => import('./classification-data-set/ClassificationDataSetList')
  ),
  Update: lazy(
    () => import('./classification-data-set/UpdateClassificationDataSet')
  ),
}

const Definitions = {
  Create: lazy(() => import('./definition/CreateDefinition')),
  List: lazy(() => import('./definition/DefinitionList')),
  Detail: lazy(() => import('./definition/DetailDefinition')),
  Query: lazy(
    () => import('./definition/definition-query/CreateDefinitionQuery')
  ),
}

const DefinitionTag = {
  Create: lazy(() => import('./definition-tag/CreateDefinitionTag')),
  List: lazy(() => import('./definition-tag/DefinitionTagList')),
  Update: lazy(() => import('./definition-tag/UpdateDefinitionTag')),
}
const SocialDefinition = {
  CreateTwitter: lazy(() => import('./definition/social-media/Twitter')),
  CreateEksi: lazy(() => import('./definition/social-media/EksiSozluk')),
  CreateReddit: lazy(() => import('./definition/social-media/Reddit')),
  CreateNews: lazy(() => import('./definition/social-media/News')),
  CreateTumblr: lazy(() => import('./definition/social-media/Tumblr')),
  CreateVK: lazy(() => import('./definition/social-media/Vk')),
  CreateYoutube: lazy(() => import('./definition/social-media/Youtube')),
  CreateInciSozluk: lazy(() => import('./definition/social-media/InciSozluk')),
  CreateInstagram: lazy(() => import('./definition/social-media/Instagram')),
  CreateLinkedin: lazy(() => import('./definition/social-media/Linkedin')),
  CreateQuora: lazy(() => import('./definition/social-media/Quora')),
  CreateMemurlar: lazy(() => import('./definition/social-media/Memurlar')),
  CreateMastodon: lazy(() => import('./definition/social-media/Mastodon')),
  CreateBooking: lazy(() => import('./definition/social-media/Booking')),
  CreateAjansPress: lazy(() => import('./definition/social-media/AjansPress')),
  createAirbnb: lazy(() => import('./definition/social-media/Airbnb')),
  CreateStarngage: lazy(() => import('./definition/social-media/StarnGage')),
  CreateTiktok: lazy(() => import('./definition/social-media/Tiktok')),
  CreateTripAdvisor: lazy(
    () => import('./definition/social-media/TripAdvisor')
  ),
  CreateUludag: lazy(() => import('./definition/social-media/UludagSozluk')),
  CreateWebsite: lazy(() => import('./definition/social-media/Website')),
  CreateGoogleTrends: lazy(
    () => import('./definition/social-media/GoogleTrends')
  ),
}

const SocialDefinitionQuery = {
  QueryTwitter: lazy(
    () => import('./definition/definition-query/social-media/Twitter')
  ),
}

const EntityCategoryType = {
  Create: lazy(() => import('./entity-category-type/CreateEntityCategoryType')),
  List: lazy(() => import('./entity-category-type/EntityCategoryTypeList')),
  Update: lazy(() => import('./entity-category-type/UpdateEntityCategoryType')),
}

const EntityType = {
  Create: lazy(() => import('./entity-type/CreateEntityType')),
  List: lazy(() => import('./entity-type/EntityTypeList')),
  Update: lazy(() => import('./entity-type/UpdateEntityType')),
}

// const ForgotPassword = {
//   List: lazy(() => import('./forgot-password')),
// }

const GenderControlSet = {
  Create: lazy(() => import('./gender-control-set/CreateGenderControlSet')),
  List: lazy(() => import('./gender-control-set/GenderControlSetList')),
  Update: lazy(() => import('./gender-control-set/UpdateGenderControlSet')),
}

const GenderDataSet = {
  Create: lazy(() => import('./gender-data-set/CreateGenderDataSet')),
  List: lazy(() => import('./gender-data-set/GenderDataSetList')),
  Update: lazy(() => import('./gender-data-set/UpdateGenderDataSet')),
}

const NamedEntityRecognition = {
  Create: lazy(
    () => import('./named-entity-recognition/CreateNamedEntityRecognition')
  ),
  List: lazy(
    () => import('./named-entity-recognition/NamedEntityRecognitionList')
  ),
  Update: lazy(
    () => import('./named-entity-recognition/UpdateNamedEntityRecognition')
  ),
}

const NamedEntityControlSet = {
  Create: lazy(
    () => import('./named-entity-control-set/CreateNamedEntityControlSet')
  ),
  List: lazy(
    () => import('./named-entity-control-set/NamedEntityControlSetList')
  ),
  Update: lazy(
    () => import('./named-entity-control-set/UpdateNamedEntityControlSet')
  ),
}

const PersonReport = {
  Create: lazy(() => import('./person-report/CreatePersonReport')),
  Detail: lazy(() => import('./person-report/DetailPersonReport')),
  Report: lazy(() => import('./person-report/Report')),
  Que: lazy(() => import('./person-report/PersonReportQueList')),
  Process: lazy(() => import('./person-report/ProcessPersonReport')),
  PdfReport: lazy(() => import('./person-report/PdfReport')),
  Pdf: lazy(() => import('./person-report/Pdf')),
}

const ProfileReport = {
  Create: lazy(() => import('./profile-report/CreateProfileReport')),
  Detail: lazy(() => import('./profile-report/DetailProfileReport')),
  Report: lazy(() => import('./profile-report/Report')),
  Que: lazy(() => import('./profile-report/ProfileReportQueList')),
  Process: lazy(() => import('./profile-report/ProcessProfileReport')),
  PdfReport: lazy(() => import('./profile-report/PdfReport')),
  Pdf: lazy(() => import('./profile-report/Pdf')),
}
const SentimentDataSet = {
  Create: lazy(() => import('./sentiment-data-set/CreateSentimentDataSet')),
  List: lazy(() => import('./sentiment-data-set/SentimentDataSetList')),
  Update: lazy(() => import('./sentiment-data-set/UpdateSentimentDataSet')),
}

const SentimentControlSet = {
  Create: lazy(
    () => import('./sentiment-control-set/CreateSentimentControlSet')
  ),
  List: lazy(() => import('./sentiment-control-set/SentimentControlSetList')),
  Update: lazy(
    () => import('./sentiment-control-set/UpdateSentimentControlSet')
  ),
}

const Source = {
  Create: lazy(() => import('./source/CreateSource')),
  List: lazy(() => import('./source/SourceList')),
  Update: lazy(() => import('./source/UpdateSource')),
}

const PartOfSpeech = {
  Create: lazy(() => import('./part-of-speech/CreatePartOfSpeech')),
  List: lazy(() => import('./part-of-speech/PartOfSpeechList')),
  Update: lazy(() => import('./part-of-speech/UpdatePartOfSpeech')),
}

const TrainingGroup = {
  Create: lazy(() => import('./training-group/CreateTrainingGroup')),
  List: lazy(() => import('./training-group/TrainingGroupList')),
  Update: lazy(() => import('./training-group/UpdateTrainingGroup')),
}

const BIToolWidget = {
  Create: lazy(() => import('./bi-tool/widget/create-widget')),
  List: lazy(() => import('./bi-tool/widget/widget-list')),
  Update: lazy(() => import('./bi-tool/widget/update-widget')),
}

const BIToolDashboard = {
  Create: lazy(() => import('./bi-tool/dashboard/create-dashboard')),
  List: lazy(() => import('./bi-tool/dashboard/dashboard-list')),
  Update: lazy(() => import('./bi-tool/dashboard/update-dashboard')),
  UpdateForSS: lazy(
    () => import('./bi-tool/dashboard/update-dashboard/index2')
  ),
}

const App = {
  Create: lazy(() => import('./identity-server/app/CreateApp')),
  List: lazy(() => import('./identity-server/app/AppList')),
  Update: lazy(() => import('./identity-server/app/UpdateApp')),
}

const Claim = {
  Create: lazy(() => import('./identity-server/claim/CreateClaim')),
  List: lazy(() => import('./identity-server/claim/ClaimList')),
  Update: lazy(() => import('./identity-server/claim/UpdateClaim')),
}

const User = {
  Create: lazy(() => import('./identity-server/user/CreateUser')),
  List: lazy(() => import('./identity-server/user/UserList')),
  Update: lazy(() => import('./identity-server/user/UpdateUser')),
}

const Role = {
  Create: lazy(() => import('./identity-server/role/CreateRole')),
  List: lazy(() => import('./identity-server/role/RoleList')),
  Update: lazy(() => import('./identity-server/role/UpdateRole')),
}

const InfluentialPeople = {
  List: lazy(() => import('./influential-people/InfluentialUserList')),
}
const NewsAlarms = {
  List: lazy(() => import('./news-alarms/NewsAlarmsList')),
}
const ManualAlarm = {
  Create: lazy(() => import('./manual-alarm/ManualAlarm')),
}
const NewsList = {
  List: lazy(() => import('./news-list/NewsList')),
  Update: lazy(() => import('./news-list/UpdateNewsList')),
}

const Alarms = {
  Create: lazy(() => import('./bi-tool/alarms/create-alarms/CreateAlarms')),
  Designer: lazy(() => import('./bi-tool/alarms/alarm-designer/AlarmDesigner')),
  Detail: lazy(() => import('./bi-tool/alarms/detail')),
  List: lazy(() => import('./bi-tool/alarms/list')),
  ManualAlarmCreate: lazy(() => import('./bi-tool/alarms/manual-alarm-create')),
}
const AlarmCommonRule = {
  Create: lazy(() => import('./bi-tool/alarms/common-rule/CreateCommonRule')),
  List: lazy(() => import('./bi-tool/alarms/common-rule/CommonRuleList')),
}

const AgendaReportV4 = {
  Create: lazy(() => import('./agenda-report-v4/create')),
  Detail: lazy(() => import('./agenda-report-v4/detail')),
  TaskDetail: lazy(() => import('./agenda-report-v4/task-detail')),
  List: lazy(() => import('./agenda-report-v4/list')),
}

const routes: RouteProps[] = [
  {
    component: Home,
    path: ['/', '/home', '/dashboard'],
  },
  {
    component: AccountProfile,
    path: '/account/profile',
  },
  {
    component: AccountPassword,
    path: '/account/password',
  },
  {
    component: British,
    path: '/british',
  },
  {
    component: SignIn,
    path: '/signin',
    auth: 'public',
  },
  {
    component: SignOut,
    path: ['/signout', '/signout/:uid'],
    auth: 'public',
  },
  {
    component: VerifyAccount,
    path: '/verify-account',
    auth: 'public',
  },
  {
    component: BIToolWidget.Create,
    path: '/bi-tool/widget/create',
  },
  {
    component: BIToolWidget.Update,
    path: '/bi-tool/widget/update/:id',
  },
  {
    component: BIToolWidget.List,
    path: '/bi-tool/widgets',
  },
  {
    component: BIToolDashboard.Create,
    path: '/bi-tool/dashboard/create',
  },
  {
    component: BIToolDashboard.Update,
    path: '/bi-tool/dashboard/update/:id',
  },
  {
    component: BIToolDashboard.UpdateForSS,
    path: '/bi-tool/dashboard/updateForSS/:id',
  },
  {
    component: BIToolDashboard.List,
    path: '/bi-tool/dashboards',
  },
  {
    component: AgendaReport.Mobile,
    path: '/agenda-report/mobile',
  },
  {
    component: AgendaReport.Stres,
    path: '/agenda-report/stres',
  },
  {
    component: AgendaReport.Second,
    path: '/agenda-report/secondreport',
  },
  {
    component: BotClassification.Create,
    path: '/bot-classification/create',
  },
  {
    component: BotClassification.Update,
    path: '/bot-classification/update/:id',
  },
  {
    component: BotClassification.List,
    path: '/bot-classification',
  },
  {
    component: BotControlSet.Create,
    path: '/bot-control-set/create',
  },
  {
    component: BotControlSet.Update,
    path: '/bot-control-set/update/:id',
  },
  {
    component: BotControlSet.List,
    path: '/bot-control-set',
  },
  {
    component: BlackList.Create,
    path: '/black-list/create',
  },
  {
    component: BlackList.Update,
    path: '/black-list/update/:id',
  },
  {
    component: BlackList.List,
    path: '/black-list',
  },
  {
    component: ManualAlarm.Create,
    path: '/manual-alarm/Create',
  },
  {
    component: ClassificationCategory.Create,
    path: '/classification-category/create',
  },
  {
    component: ClassificationCategory.Update,
    path: '/classification-category/update/:id',
  },
  {
    component: ClassificationCategory.List,
    path: '/classification-category',
  },
  {
    component: ClassificationControlSet.Create,
    path: '/classification-control-set/create',
  },
  {
    component: ClassificationControlSet.Update,
    path: '/classification-control-set/update/:id',
  },
  {
    component: ClassificationControlSet.List,
    path: '/classification-control-set',
  },
  {
    component: ClassificationDataSet.Create,
    path: '/classification-data-set/create',
  },
  {
    component: ClassificationDataSet.Update,
    path: '/classification-data-set/update/:id',
  },
  {
    component: ClassificationDataSet.List,
    path: '/classification-data-set',
  },
  {
    component: Definitions.Create,
    path: '/definition/create',
  },
  {
    component: Definitions.List,
    path: '/definition',
  },
  {
    component: Definitions.Detail,
    path: '/definition/detail/:id',
  },
  {
    component: DefinitionTag.Create,
    path: '/definition-tag/create',
  },
  {
    component: DefinitionTag.List,
    path: '/definition-tag',
  },
  {
    component: DefinitionTag.Update,
    path: '/definition-tag/update/:id',
  },
  {
    component: SocialDefinition.CreateTwitter,
    path: '/definition/create/x',
  },
  {
    component: SocialDefinition.CreateEksi,
    path: '/definition/create/eksi-sozluk',
  },
  {
    component: SocialDefinition.CreateInciSozluk,
    path: '/definition/create/inci-sozluk',
  },
  {
    component: SocialDefinition.CreateBooking,
    path: '/definition/create/booking',
  },
  {
    component: SocialDefinition.CreateGoogleTrends,
    path: '/definition/create/google-trends',
  },
  {
    component: SocialDefinition.createAirbnb,
    path: '/definition/create/airbnb',
  },
  {
    component: SocialDefinition.CreateAjansPress,
    path: '/definition/create/ajans-press',
  },
  {
    component: SocialDefinition.CreateReddit,
    path: '/definition/create/reddit',
  },
  {
    component: SocialDefinition.CreateInstagram,
    path: '/definition/create/instagram',
  },
  {
    component: SocialDefinition.CreateLinkedin,
    path: '/definition/create/linkedin',
  },
  {
    component: SocialDefinition.CreateQuora,
    path: '/definition/create/quora',
  },
  {
    component: SocialDefinition.CreateMemurlar,
    path: '/definition/create/memurlar',
  },
  {
    component: SocialDefinition.CreateMastodon,
    path: '/definition/create/mastodon',
  },
  {
    component: SocialDefinition.CreateNews,
    path: '/definition/create/news',
  },
  {
    component: SocialDefinition.CreateTumblr,
    path: '/definition/create/tumblr',
  },
  {
    component: SocialDefinition.CreateVK,
    path: '/definition/create/vk',
  },
  {
    component: SocialDefinition.CreateYoutube,
    path: '/definition/create/youtube',
  },
  {
    component: SocialDefinition.CreateStarngage,
    path: '/definition/create/starngage',
  },

  {
    component: SocialDefinition.CreateTiktok,
    path: '/definition/create/tiktok',
  },

  {
    component: SocialDefinition.CreateTripAdvisor,
    path: '/definition/create/tripadvisor',
  },

  {
    component: SocialDefinition.CreateUludag,
    path: '/definition/create/uludag-sozluk',
  },
  {
    component: SocialDefinition.CreateWebsite,
    path: '/definition/create/website',
  },
  {
    component: Definitions.Query,
    path: '/definition/query',
  },
  {
    component: SocialDefinitionQuery.QueryTwitter,
    path: '/definition/query/x',
  },
  {
    component: EntityCategoryType.Create,
    path: '/entity-category-type/create',
  },
  {
    component: EntityCategoryType.Update,
    path: '/entity-category-type/update/:id',
  },
  {
    component: EntityCategoryType.List,
    path: '/entity-category-type',
  },
  {
    component: EntityType.Create,
    path: '/entity-type/create',
  },
  {
    component: EntityType.Update,
    path: '/entity-type/update/:id',
  },
  {
    component: EntityType.List,
    path: '/entity-type',
  },
  {
    component: ForgotPasssord,
    path: '/forgot-password',
  },
  {
    component: GenderControlSet.Create,
    path: '/gender-control-set/create',
  },
  {
    component: GenderControlSet.Update,
    path: '/gender-control-set/update/:id',
  },
  {
    component: GenderControlSet.List,
    path: '/gender-control-set',
  },
  {
    component: GenderDataSet.Create,
    path: '/gender-data-set/create',
  },
  {
    component: GenderDataSet.Update,
    path: '/gender-data-set/update/:id',
  },
  {
    component: GenderDataSet.List,
    path: '/gender-data-set',
  },
  {
    component: NamedEntityRecognition.Create,
    path: '/named-entity-recognition/create',
  },
  {
    component: NamedEntityRecognition.Update,
    path: '/named-entity-recognition/update/:id',
  },
  {
    component: NamedEntityRecognition.List,
    path: '/named-entity-recognition',
  },
  {
    component: NamedEntityControlSet.Create,
    path: '/named-entity-control-set/create',
  },
  {
    component: NamedEntityControlSet.Update,
    path: '/named-entity-control-set/update/:id',
  },
  {
    component: NamedEntityControlSet.List,
    path: '/named-entity-control-set',
  },
  {
    component: PersonReport.Report,
    path: '/report/:id',
  },
  {
    component: PersonReport.Create,
    path: '/person-report/create',
  },
  {
    component: PersonReport.Detail,
    path: '/person-report/detail/:id',
  },
  {
    component: PersonReport.Que,
    path: '/person-report',
  },
  {
    component: PersonReport.PdfReport,
    path: '/person-report/pdf-report/:id',
  },
  {
    component: PersonReport.Pdf,
    path: '/person-report/pdf',
  },
  {
    component: PersonReport.Process,
    path: '/person-report/process/detail/:id',
  },
  {
    component: SentimentDataSet.Create,
    path: '/sentiment-data-set/create',
  },
  {
    component: SentimentDataSet.Update,
    path: '/sentiment-data-set/update/:id',
  },
  {
    component: SentimentDataSet.List,
    path: '/sentiment-data-set',
  },
  {
    component: SentimentControlSet.Create,
    path: '/sentiment-control-set/create',
  },
  {
    component: SentimentControlSet.Update,
    path: '/sentiment-control-set/update/:id',
  },
  {
    component: SentimentControlSet.List,
    path: '/sentiment-control-set',
  },
  {
    component: ProfileReport.Report,
    path: '/report/:id',
  },
  {
    component: ProfileReport.Create,
    path: '/profile-report/create',
  },
  {
    component: ProfileReport.Detail,
    path: '/profile-report/detail/:id',
  },
  {
    component: ProfileReport.Que,
    path: '/profile-report',
  },
  {
    component: ProfileReport.PdfReport,
    path: '/profile-report/pdf-report/:id',
  },
  {
    component: ProfileReport.Pdf,
    path: '/profile-report/pdf',
  },
  {
    component: ProfileReport.Process,
    path: '/profile-report/process/detail/:id',
  },
  {
    component: PartOfSpeech.Create,
    path: '/part-of-speech/create',
  },
  {
    component: PartOfSpeech.List,
    path: '/part-of-speech',
  },
  {
    component: PartOfSpeech.Update,
    path: '/part-of-speech/update/:id',
  },
  {
    component: Source.Create,
    path: '/source/create',
  },
  {
    component: Source.List,
    path: '/source',
  },
  {
    component: Source.Update,
    path: '/source/update/:id',
  },
  {
    component: TrainingGroup.List,
    path: '/training-group',
  },
  {
    component: TrainingGroup.Create,
    path: '/training-group/create',
  },
  {
    component: TrainingGroup.Update,
    path: '/training-group/update/:id',
  },
  {
    component: App.Create,
    path: '/app/create',
  },
  {
    component: App.List,
    path: '/apps',
  },
  {
    component: App.Update,
    path: '/app/update/:id',
  },
  {
    component: Claim.Create,
    path: '/claim/create',
  },
  {
    component: Claim.List,
    path: '/claims',
  },
  {
    component: Claim.Update,
    path: '/claim/update/:id',
  },
  {
    component: User.Create,
    path: '/user/create',
  },
  {
    component: User.List,
    path: '/users',
  },
  {
    component: User.Update,
    path: '/user/update/:id',
  },
  {
    component: Role.Create,
    path: '/role/create',
  },
  {
    component: Role.List,
    path: '/roles',
  },
  {
    component: Role.Update,
    path: '/role/update/:id',
  },
  { component: InfluentialPeople.List, path: '/influential-people' },
  {
    component: NewsList.Update,
    path: '/news-list/update/:id',
  },
  {
    component: NewsList.List,
    path: '/news-list',
  },
  {
    component: NewsAlarms.List,
    path: '/news-alarms',
  },
  {
    component: Alarms.ManualAlarmCreate,
    path: '/manual-alarm-create',
  },
  {
    component: Alarms.List,
    path: '/alarms',
  },
  {
    component: Alarms.Create,
    path: '/alarms/create',
  },
  {
    component: Alarms.Detail,
    path: '/alarms/detail/:id',
  },
  {
    component: AlarmCommonRule.List,
    path: '/alarms/common-rule',
  },
  {
    component: AlarmCommonRule.Create,
    path: '/alarms/common-rule/create',
  },
  {
    component: AgendaReportV4.Create,
    path: '/agenda-reportv4/create',
  },
  {
    component: AgendaReportV4.Detail,
    path: '/agenda-reportv4/detail/:id',
  },
  {
    component: AgendaReportV4.TaskDetail,
    path: '/agenda-reportv4/task-detail/:id',
  },
  {
    component: AgendaReportV4.List,
    path: '/agenda-reportv4',
  },
]

export default routes
